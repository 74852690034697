@use '@angular/material' as mat;
@use './colors';

/**
 Movinmotion Material Theme Typography
 */
$mm-font-family: 'Lato, sans-serif';
$mm-typography: map-merge(
  mat.define-legacy-typography-config(
    $font-family: $mm-font-family,
    // Classes: .mat-display-4
    // Usage: Large, one-off header, usually at the top of the page
    $display-4: mat.define-typography-level(98px, 118px, 900, $letter-spacing: -0.05em),
    // Classes: .mat-display-3
    // Usage: Large, one-off header, usually at the top of the page
    $display-3: mat.define-typography-level(64px, 77px, 900, $letter-spacing: -0.02em),
    // Classes: .mat-display-2
    // Usage: Large, one-off header, usually at the top of the page
    $display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
    // Classes: .mat-display-1
    // Usage: Large, one-off header, usually at the top of the page
    $display-1: mat.define-typography-level(34px, 40px, 400),
    // Classes: .mat-h1, .mat-headline, .mat-headline-1
    // Usage: Section heading corresponding to the <h1> tag
    $headline: mat.define-typography-level(36px, 42px, 900),
    // Classes: .mat-h2, .mat-title
    // Usage: Section heading corresponding to the <h2> tag
    $title: mat.define-typography-level(20px, 32px, 500),
    // Classes: .mat-h3, .mat-subheading-2
    // Usage: Section heading corresponding to the <h3> tag
    $subheading-2: mat.define-typography-level(16px, 22px, 900, $letter-spacing: 0.2em),
    // Classes: .mat-h4, .mat-subheading-1
    // Usage: Section heading corresponding to the <h4> tag
    $subheading-1: mat.define-typography-level(10px, 14px, 900, $letter-spacing: 0.2em),
    // Classes: .mat-body-strong, .mat-body-2
    // Usage: Bolder body text
    $body-2: mat.define-typography-level(24px, 32px, 900),
    // Classes: .mat-body, .mat-body-1
    // Usage: Base body text
    $body-1: mat.define-typography-level(14px, 25px, 400),
    // Classes: .mat-small, .mat-caption
    // Usage: Smaller body and hint text
    $caption: mat.define-typography-level(12px, 22px, 400),
    // Classes: none
    // Usage: Buttons and anchors
    $button: mat.define-typography-level(12px, 22px, 900, $letter-spacing: 0.2em),
    // Classes: none
    // Usage: Form input fields
    // Line-height must be unit-less fraction of the font-size
    $input: mat.define-typography-level(24px, 1.35, 400),
  ),
  /* Custom typography level for Movinmotion, font-family is required in function param there is not default like above */
    (
      // Classes: .mat-headline-1
      // Usage: Section heading lighter weight
      headline-2: mat.define-typography-level(36px, 42px, 400),
      // Classes: .mat-body-large, .mat-body-3
      // Usage: Larger body text
      body-3: mat.define-typography-level(24px, 32px, 400, $mm-font-family, -0.05em),
      // Classes: .mat-body-large-strong, .mat-body-4
      // Usage: Larger and bolder body text
      body-4: mat.define-typography-level(24px, 28px, 900, $mm-font-family),
      // Classes: .mat-small-4
      // Usage: Very small and bold text
      small-4: mat.define-typography-level(10px, 14px, 900, $mm-font-family, $letter-spacing: 0.2em),
      // Classes: .mat-small-3
      // Usage: Very small text
      small-3: mat.define-typography-level(10px, 14px, 400, $mm-font-family, $letter-spacing: 0.2em),
      // Classes: .mat-small-2
      // Usage: Small and bold text
      small-2: mat.define-typography-level(12px, 16px, 900, $mm-font-family),
      // Classes: .mat-small-1
      // Usage: Small text
      small-1: mat.define-typography-level(12px, 16px, 400, $mm-font-family),
      // Classes: .mat-medium-2
      // Usage: Medium bold text
      medium-2: mat.define-typography-level(14px, 25px, 900, $mm-font-family),
      // Classes: .mat-medium-1
      // Usage: Medium bold text
      medium-1: mat.define-typography-level(16px, 22px, 900, $mm-font-family)
    )
);

/*
 Movinmotion Material Themes Color
 */
$mm-theme-accent: mat.define-palette(colors.$mm-blue);
$mm-theme-warn: mat.define-palette(colors.$mm-grey);

$mm-core-theme-primary: mat.define-palette(colors.$mm-red);
$mm-core-theme: mat.define-light-theme(
  (
    color: (
      primary: $mm-core-theme-primary,
      accent: $mm-theme-accent,
      warn: $mm-theme-warn,
    ),
    typography: $mm-typography,
    density: 0,
  )
);

$mm-talents-theme-primary: mat.define-palette(colors.$mm-yellow);
$mm-talents-theme: mat.define-light-theme(
  (
    color: (
      primary: $mm-talents-theme-primary,
      accent: $mm-theme-accent,
      warn: $mm-theme-warn,
    ),
    typography: $mm-typography,
    density: 0,
  )
);

$mm-accounting-theme-primary: mat.define-palette(colors.$mm-clear-blue);
$mm-accounting-theme: mat.define-light-theme(
  (
    color: (
      primary: $mm-accounting-theme-primary,
      accent: $mm-theme-accent,
      warn: $mm-theme-warn,
    ),
    typography: $mm-typography,
    density: 0,
  )
);

$mm-social-theme-primary: mat.define-palette(colors.$mm-mauve);
$mm-social-theme: mat.define-light-theme(
  (
    color: (
      primary: $mm-social-theme-primary,
      accent: $mm-theme-accent,
      warn: $mm-theme-warn,
    ),
    typography: $mm-typography,
    density: 0,
  )
);

$mm-worker-theme-primary: mat.define-palette(colors.$mm-dark-green);
$mm-worker-theme: mat.define-light-theme(
  (
    color: (
      primary: $mm-worker-theme-primary,
      accent: $mm-theme-accent,
      warn: $mm-theme-warn,
    ),
    typography: $mm-typography,
    density: 0,
  )
);

$mm-film-france-theme-primary: mat.define-palette(colors.$mm-dark-yellow);
$mm-film-france-theme: mat.define-light-theme(
  (
    color: (
      primary: $mm-film-france-theme-primary,
      accent: $mm-theme-accent,
      warn: $mm-theme-warn,
    ),
    typography: $mm-typography,
    density: 0,
  )
);

$mm-odalie-theme-primary: mat.define-palette(colors.$mm-orange);
$mm-odalie-theme: mat.define-light-theme(
  (
    color: (
      primary: $mm-odalie-theme-primary,
      accent: $mm-theme-accent,
      warn: $mm-theme-warn,
    ),
    typography: $mm-typography,
    density: 0,
  )
);
