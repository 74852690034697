@use '@angular/material' as mat;
@use '../variables';

// Material Table

@mixin mm-component-material-table($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  table.mat-table {
    width: 100%;
    & th.mat-header-cell:first-of-type,
    & td.mat-cell:first-of-type,
    & td.mat-footer-cell:first-of-type {
      padding-left: 0;
    }
    & th.mat-header-cell:last-of-type,
    & td.mat-cell:last-of-type,
    & td.mat-footer-cell:last-of-type {
      padding-right: 0;
    }
    & th.mat-header-cell {
      color: mat.get-color-from-palette($warn-color, 175);
      border: none;
      text-transform: uppercase;
      @include mat.typography-level(variables.$mm-typography, small-4);
    }
    & tr.mat-header-row {
      height: 30px;
    }
    & tr.mat-row {
      & td.mat-cell {
        padding: 10px 0;
        border-color: mat.get-color-from-palette($accent-color, 100);
      }
    }
  }
}

@mixin mm-component-material-table-color($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);
}
