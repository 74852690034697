@use '@angular/material' as mat;
@use '../variables';

// List

@mixin mm-component-list($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-list {
    list-style-type: none;
    padding-left: 0;

    & li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      & .mm-list-label {
        @include mat.typography-level(variables.$mm-typography, small-4);
        color: mat.get-color-from-palette($warn-color, 175);
        text-transform: uppercase;
        flex: 50%;

        & > sup {
          @include mat.typography-level(variables.$mm-typography, small-3);
          letter-spacing: 0;
          color: mat.get-color-from-palette($primary-color);
          text-transform: none;
          position: relative;
          top: -3px;
          left: -3px;
        }
      }

      & .mm-list-field {
        & .mm-input-container {
          margin: 0;

          & .mm-label {
            display: none;
          }
        }

        flex: 50%;
      }

      & .mat-expansion-panel {
        flex: 100%;

        &:not([class*='mat-elevation-z']) {
          box-shadow: none;
        }

        &:not(.mat-expanded) {
          & .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
            background: none;
          }
        }

        & .mat-expansion-panel-header {
          & ul {
            $sublist-padding-left: 20px;
            padding: 0;
            flex: 100%;

            & .mm-list-label {
              flex: calc(50% - #{$sublist-padding-left});
            }
          }

          @include mat.typography-level(variables.$mm-typography, body-1);
          display: flex;
          align-items: center;
          padding: 0;

          & .mat-icon {
            color: mat.get-color-from-palette($primary-color);
            margin: auto 15px auto 0;
            font-size: 10px;
            line-height: 18px;
            height: 18px;
            width: 8px;
          }

          & .mat-icon:last-child {
            display: none;
          }
        }

        & .mat-expansion-panel-body {
          border-left: 1px solid mat.get-color-from-palette($primary-color);
          margin-left: 4px;
          padding: 20px 0 0 20px;
        }

        // Sub list
        & .mat-expansion-panel-content {
          & ul {
            & .mat-expansion-panel-body {
              padding-top: 20px;
            }

            padding: 0;
            $sublist-padding-left: 24px;
            flex: 100%;

            & .mm-list-label {
              flex: calc(50% - #{$sublist-padding-left});
            }

            & li {
              &:first-child {
                margin-top: 0;
              }

              &:last-child {
                margin-bottom: 0;
              }

              & ul {
                & li ul .mm-list-label {
                  flex: calc(50% - #{2 * $sublist-padding-left});
                }

                & li ul li ul .mm-list-label {
                  flex: calc(50% - #{3 * $sublist-padding-left});
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin mm-component-list-color($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-list {
    & li {
      & .mm-list-label {
        color: mat.get-color-from-palette($warn-color, 175);

        & > sup {
          color: mat.get-color-from-palette($primary-color);
        }
      }

      & .mat-expansion-panel {
        & .mat-expansion-panel-header {
          & .mat-icon {
            color: mat.get-color-from-palette($primary-color);
          }
        }

        & .mat-expansion-panel-body {
          border-left: 1px solid mat.get-color-from-palette($primary-color);
        }
      }
    }
  }
}
