@use '@angular/material' as mat;
@use 'styles/variables';
@use './styles/components';

html,
body {
  height: 100%;
}

body {
  @include mat.typography-level(variables.$mm-typography, body-1);
  margin: 0;
  color: mat.get-color-from-palette(variables.$mm-blue, 800);
}

// Init Material theme and Movinmotion components
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($mm-typography);`
@include mat.all-legacy-component-typographies(variables.$mm-typography);
@include mat.legacy-core();
@include mat.all-legacy-component-themes(variables.$theme-selected);
@include components.mm-components(variables.$theme-selected);

.accounting {
  @include mat.all-legacy-component-colors(variables.$mm-accounting-theme);
  @include components.mm-components-color(variables.$mm-accounting-theme);
}

.social {
  @include mat.all-legacy-component-colors(variables.$mm-social-theme);
  @include components.mm-components-color(variables.$mm-social-theme);
}

.talents {
  @include mat.all-legacy-component-colors(variables.$mm-talents-theme);
  @include components.mm-components-color(variables.$mm-talents-theme);
}

.worker {
  @include mat.all-legacy-component-colors(variables.$mm-worker-theme);
  @include components.mm-components-color(variables.$mm-worker-theme);
}

.fft {
  @include mat.all-legacy-component-colors(variables.$mm-film-france-theme);
  @include components.mm-components-color(variables.$mm-film-france-theme);
}

.odalie {
  @include mat.all-legacy-component-colors(variables.$mm-odalie-theme);
  @include components.mm-components-color(variables.$mm-odalie-theme);
}
