@use '@angular/material' as mat;
@use '../variables';

// Input

@mixin mm-component-input($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-input-container {
    width: 100%;
    position: relative;
    margin-top: 10px;
    margin-bottom: 20px;

    & .mm-input-form {
      position: relative;
      display: block;
      z-index: 50;
    }

    & .mm-input-field {
      color: mat.get-color-from-palette($warn-color, 600);

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    & .mm-input-error {
      position: relative;
      display: flex;
      background: mat.get-color-from-palette($primary-color, 500);
      color: mat.get-color-from-palette($primary-color, A100);
      border-radius: 0 0 10px 10px;
      left: 0;
      right: 0;
      margin-top: -10px;
      padding: 15px 10px 5px 10px;
      align-items: center;
      @include mat.typography-level(variables.$mm-typography, small-1);

      & .mm-input-error-icon {
        width: 14px;
        margin-right: 5px;
      }
    }
  }
  .mm-input-info {
    margin-bottom: 10px;

    & .icon {
      width: 16px;
    }

    & .text {
      margin-left: 10px;
    }
  }
}

@mixin mm-component-input-color($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-input-container {
    & .mm-input-field {
      color: mat.get-color-from-palette($warn-color, 600);
    }

    & .mm-input-error {
      background: mat.get-color-from-palette($primary-color, 500);
      color: mat.get-color-from-palette($primary-color, A100);
    }
  }
}
