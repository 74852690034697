@use '@angular/material' as mat;
@use '../variables';
@use '@angular/flex-layout/core/sass/layout-bp';

// Common

@mixin mm-component-common($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-text-primary {
    color: mat.get-color-from-palette($primary-color, 500);
  }
  .mm-text-accent {
    color: mat.get-color-from-palette($accent-color, 500);
  }
  .mm-bg-primary {
    background-color: mat.get-color-from-palette($primary-color, 10);
  }
  .mm-bg-accent {
    background-color: mat.get-color-from-palette($accent-color, 100);
  }
  .mat-chip.mat-standard-chip.mm-primary,
  .mm-primary {
    color: mat.get-color-from-palette($primary-color, 500);
    background-color: mat.get-color-from-palette($primary-color, 10);
  }
  .mat-chip.mat-standard-chip.mm-accent,
  .mm-accent {
    color: mat.get-color-from-palette($accent-color, 500);
    background-color: mat.get-color-from-palette($accent-color, 100);
  }
  .mat-chip.mat-standard-chip.mm-green,
  .mm-green {
    color: mat.get-color-from-palette(variables.$mm-blue, 800);
    background-color: mat.get-color-from-palette(variables.$mm-green, 200);

    &.light {
      color: mat.get-color-from-palette(variables.$mm-blue, 500);
    }
  }
  .mm-container {
    max-width: 1180px;
    width: 100%;
    margin: 0 auto 30px auto;
    @include layout-bp.layout-bp(lt-lg) {
      padding: 0 20px;
    }
  }
  .mm-error {
    background: mat.get-color-from-palette(variables.$mm-red, 500);
    color: mat.get-color-from-palette(variables.$mm-red, A100);
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 10px 5px 10px;
    @include mat.typography-level(variables.$mm-typography, small-1);
  }
  .mm-label {
    display: block;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: mat.get-color-from-palette($warn-color, 800);
    @include mat.typography-level(variables.$mm-typography, small-4);
  }
  .mm-currency {
    @include mat.typography-level(variables.$mm-typography, small-1);
    color: mat.get-color-from-palette($primary-color, 900);
    position: relative;
    left: -3px;
    top: -3px;
  }
  .mm-link {
    color: mat.get-color-from-palette($primary-color, 500);
    text-decoration: none;
    position: relative;
    cursor: pointer;

    &:hover {
      text-decoration: mat.get-color-from-palette($primary-color, 500);
      text-decoration: underline;
    }
  }
  .mm-buttons-container {
    text-align: right;
    padding-top: 20px;
  }
}

@mixin mm-component-common-color($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-text-primary {
    color: mat.get-color-from-palette($primary-color, 500);
  }
  .mm-text-accent {
    color: mat.get-color-from-palette($accent-color, 500);
  }
  .mm-bg-primary {
    background-color: mat.get-color-from-palette($primary-color, 10);
  }
  .mm-bg-accent {
    background-color: mat.get-color-from-palette($accent-color, 100);
  }
  .mat-chip.mat-standard-chip.mm-primary,
  .mm-primary {
    color: mat.get-color-from-palette($primary-color, 500);
    background-color: mat.get-color-from-palette($primary-color, 10);
  }
  .mat-chip.mat-standard-chip.mm-accent,
  .mm-accent {
    color: mat.get-color-from-palette($accent-color, 500);
    background-color: mat.get-color-from-palette($accent-color, 100);
  }
  .mm-label {
    color: mat.get-color-from-palette($warn-color, 800);
  }
  .mm-currency {
    color: mat.get-color-from-palette($primary-color, 900);
  }
  .mm-link {
    color: mat.get-color-from-palette($primary-color, 500);

    &:hover {
      text-decoration: mat.get-color-from-palette($primary-color, 500);
    }
  }
}
