@use './variables';
@use './components/loading';
@use './components/material-toolbar';
@use './components/material-button';
@use './components/material-checkbox';
@use './components/material-card';
@use './components/material-chip';
@use './components/material-table';
@use './components/typography';
@use './components/common';
@use './components/material-tooltip';
@use './components/material-menu';
@use './components/input';
@use './components/list';
@use './components/nav';
@use './components/sidenav';

/*
 Custom Movinmotion classes
 */
@mixin mm-components($theme: variables.$theme-selected) {
  @include loading.mm-component-loading($theme);
  @include material-toolbar.mm-component-material-toolbar($theme);
  @include material-button.mm-component-material-button($theme);
  @include material-checkbox.mm-component-material-checkbox($theme);
  @include material-card.mm-component-material-card($theme);
  @include material-chip.mm-component-material-chip($theme);
  @include material-table.mm-component-material-table($theme);
  @include typography.mm-component-typography($theme);
  @include common.mm-component-common($theme);
  @include material-tooltip.mm-component-material-tooltip($theme);
  @include material-menu.mm-component-material-menu($theme);
  @include input.mm-component-input($theme);
  @include list.mm-component-list($theme);
  @include nav.mm-component-nav($theme);
  @include sidenav.mm-component-sidenav($theme);
}

/*
 Custom Movinmotion color for custom classes
 */
@mixin mm-components-color($theme: variables.$theme-selected) {
  @include loading.mm-component-loading-color($theme);
  @include material-toolbar.mm-component-material-toolbar-color($theme);
  @include material-button.mm-component-material-button-color($theme);
  @include material-checkbox.mm-component-material-checkbox-color($theme);
  @include material-card.mm-component-material-card-color($theme);
  @include material-chip.mm-component-material-chip-color($theme);
  @include material-table.mm-component-material-table-color($theme);
  @include typography.mm-component-typography-color($theme);
  @include common.mm-component-common-color($theme);
  @include material-tooltip.mm-component-material-tooltip-color($theme);
  @include material-menu.mm-component-material-menu-color($theme);
  @include input.mm-component-input-color($theme);
  @include list.mm-component-list-color($theme);
  @include nav.mm-component-nav-color($theme);
  @include sidenav.mm-component-sidenav-color($theme);
}
