@use '@angular/material' as mat;
@use '../variables';

// Nav

@mixin mm-component-nav($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-nav {
    width: 100%;
    max-height: 80px;
    padding: 0 6px;
    background: mat.get-color-from-palette($warn-color, 10);
    a,
    button {
      @include mat.typography-level(variables.$mm-typography, medium-1);
      font-weight: 400;
      padding: 10px;
    }
    & .brand,
    & a.brand {
      display: inline-block;
    }
    & .service-button {
      margin: 0 10px 0 10px;
      width: auto;
      height: auto;
    }
    & ul {
      list-style-type: none;
      padding: 0;
      & li {
        display: inline;
        color: mat.get-color-from-palette($accent-color, 150);
        & + li {
          margin-left: 15px;
        }
        & a {
          padding: 29px 10px;
          &.active {
            font-weight: 900;
            color: mat.get-color-from-palette($accent-color, 800);
          }
        }
      }
    }
    & .company-button {
      margin: 0 15px;
      padding: 27px 10px;
      color: mat.get-color-from-palette($accent-color, 150);
      & mat-icon {
        opacity: 0.2;
        margin-right: 10px;
        width: 14px;
        height: 16px;
      }
    }
    & .user-button {
      margin: 0;
      padding: 14px 10px;
      & .username {
        margin-left: 10px;
        color: mat.get-color-from-palette($accent-color, 150);
      }
    }
    &.sub-nav {
      background: mat.get-color-from-palette($accent-color, 800);
      max-height: 50px;
      padding-left: 290px;
      & a {
        color: mat.get-color-from-palette($primary-color, A100);
        height: 50px;
        line-height: 50px;
        padding: 0 10px;
        &.active {
          font-weight: 900;
          color: mat.get-color-from-palette($primary-color, A100);
        }
      }
    }
  }
}

@mixin mm-component-nav-color($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-nav {
    background: mat.get-color-from-palette($warn-color, 10);
    & ul {
      & li {
        color: mat.get-color-from-palette($accent-color, 150);
        & a {
          &.active {
            color: mat.get-color-from-palette($accent-color, 800);
          }
        }
      }
    }
    & .company-button {
      color: mat.get-color-from-palette($accent-color, 150);
    }
    & .user-button {
      & .username {
        color: mat.get-color-from-palette($accent-color, 150);
      }
    }
    &.sub-nav {
      background: mat.get-color-from-palette($accent-color, 800);
      & a {
        color: mat.get-color-from-palette($primary-color, A100);
        &.active {
          color: mat.get-color-from-palette($primary-color, A100);
        }
      }
    }
  }
}
