@use '@angular/material' as mat;
@use '../variables';

// Material Checkbox

@mixin mm-component-material-checkbox($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mat-checkbox {
    &.small .mat-checkbox {
      &-inner-container {
        height: 16px;
        width: 16px;
      }
      &-layout {
        align-items: center;
        white-space: normal;
        -webkit-user-select: auto;
        -moz-user-select: auto;
        -ms-user-select: auto;
        user-select: auto;

        & .mat-checkbox-label {
          line-height: 16px;
        }
      }
      &-inner-container,
      &-frame,
      &-indeterminate,
      &-checked {
        &,
        & .mat-checkbox-background {
          border-radius: 5px;
        }
      }
    }
    &.large .mat-checkbox-inner-container {
      height: 50px;
      width: 50px;
    }
    & .mat-checkbox {
      &-inner-container {
        height: 30px;
        width: 30px;
      }
      &-layout {
        align-items: center;
        white-space: normal;
        -webkit-user-select: auto;
        -moz-user-select: auto;
        -ms-user-select: auto;
        user-select: auto;

        & .mat-checkbox-label {
          line-height: 25px;
        }
      }
      &-inner-container,
      &-frame,
      &-indeterminate,
      &-checked {
        &,
        & .mat-checkbox-background {
          border-radius: 10px;
        }
      }
    }
  }
}

@mixin mm-component-material-checkbox-color($theme: variables.$theme-selected) {
  $color: mat.get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);
}
